import About from './about';
import Contact from './contact';
import Url from './url';
import Featured from './featured';
import Footer from './footer';
import Head from './head';
import Intro from './intro';
import Cv from './cv';
import Layout from './layout';
import Loader from './loader';
import Menu from './menu';
import Nav from './nav';
import Projects from './projects';
import Social from './social';

export {
  About,
  Contact,
  Url,
  Featured,
  Footer,
  Head,
  Intro,
  Cv,
  Layout,
  Loader,
  Menu,
  Nav,
  Projects,
  Social,
};
