import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="850" height="240" viewBox="0 0 85 24">
    <title>Loader Logo</title>
    <g>
      <g id="underscore">
        <path
          d="M11.077,18.349v7.6H8.97v-.7a2.619,2.619,0,0,1-2.04.867,2.543,2.543,0,0,1-1.964-.764,2.891,2.891,0,0,1-.7-2.04V18.349H6.368v4.539a1.463,1.463,0,0,0,.307.985,1.143,1.143,0,0,0,.918.357,1.408,1.408,0,0,0,.867-.28,1.317,1.317,0,0,0,.492-.774V18.349Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M12.9,25.947v-7.6H15v.7a2.554,2.554,0,0,1,2.04-.866,2.558,2.558,0,0,1,1.98.764,2.888,2.888,0,0,1,.706,2.04v4.964H17.622V21.425a1.445,1.445,0,0,0-.314-.977,1.156,1.156,0,0,0-.927-.366,1.448,1.448,0,0,0-.875.28,1.308,1.308,0,0,0-.5.775v4.81Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M26.36,25.947v-.629a2.218,2.218,0,0,1-.8.561,2.654,2.654,0,0,1-1.106.238,3.088,3.088,0,0,1-2.524-1.131,4.307,4.307,0,0,1-.927-2.83,4.347,4.347,0,0,1,.927-2.839,3.082,3.082,0,0,1,2.524-1.138,2.669,2.669,0,0,1,1.106.237,2.219,2.219,0,0,1,.8.562V14.9h2.125v11.05Zm0-2.55V20.9a1.523,1.523,0,0,0-.578-.637,1.566,1.566,0,0,0-.849-.246,1.626,1.626,0,0,0-1.327.6,2.351,2.351,0,0,0-.493,1.538,2.321,2.321,0,0,0,.493,1.531,1.636,1.636,0,0,0,1.327.594,1.555,1.555,0,0,0,.858-.246A1.538,1.538,0,0,0,26.36,23.4Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M34.928,23.465h1.955A3.031,3.031,0,0,1,35.8,25.412a3.535,3.535,0,0,1-2.287.705,3.476,3.476,0,0,1-2.669-1.1,4.038,4.038,0,0,1-1.02-2.865,4.074,4.074,0,0,1,1.028-2.847,3.4,3.4,0,0,1,2.627-1.13,3.212,3.212,0,0,1,2.541,1.028,3.991,3.991,0,0,1,.91,2.745V22.6H31.868a2.1,2.1,0,0,0,.493,1.334,1.49,1.49,0,0,0,1.156.484A1.278,1.278,0,0,0,34.928,23.465ZM33.5,19.9a1.523,1.523,0,0,0-1.581,1.427H34.86a1.551,1.551,0,0,0-.374-1.028A1.252,1.252,0,0,0,33.5,19.9Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M42.885,18.314v2.108a2.023,2.023,0,0,0-1.055-.255,1.562,1.562,0,0,0-.935.281,1.3,1.3,0,0,0-.51.79v4.709H38.277v-7.6h2.108V19.1a1.833,1.833,0,0,1,1.7-.918A1.733,1.733,0,0,1,42.885,18.314Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M43.683,23.687h2.09a1.178,1.178,0,0,0,1.276.833q1.071,0,1.071-.749a.483.483,0,0,0-.179-.4,1.544,1.544,0,0,0-.587-.247l-1.529-.34q-1.973-.459-1.972-2.141a2.167,2.167,0,0,1,.867-1.777,3.363,3.363,0,0,1,2.159-.688,3.561,3.561,0,0,1,2.219.62,2.7,2.7,0,0,1,1.01,1.725h-2a1.1,1.1,0,0,0-1.19-.748,1.3,1.3,0,0,0-.706.187.591.591,0,0,0-.3.527q0,.425.68.6l1.564.357q2.022.494,2.023,2.21a2.139,2.139,0,0,1-.875,1.8,3.6,3.6,0,0,1-2.237.663,3.908,3.908,0,0,1-2.311-.638A2.547,2.547,0,0,1,43.683,23.687Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M51.129,22.156a4.075,4.075,0,0,1,1.019-2.873,3.764,3.764,0,0,1,4.914-.357,3.051,3.051,0,0,1,1.053,1.989H56.042a1.084,1.084,0,0,0-.417-.688,1.376,1.376,0,0,0-.842-.247,1.332,1.332,0,0,0-1.138.595,2.662,2.662,0,0,0-.425,1.581,2.633,2.633,0,0,0,.425,1.565,1.331,1.331,0,0,0,1.138.594,1.37,1.37,0,0,0,.842-.247,1.079,1.079,0,0,0,.417-.688h2.073a3.06,3.06,0,0,1-1.045,1.989,3.773,3.773,0,0,1-4.922-.356A4.054,4.054,0,0,1,51.129,22.156Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M66.4,22.156a4.008,4.008,0,0,1-1.038,2.857,3.819,3.819,0,0,1-5.372,0,4.011,4.011,0,0,1-1.036-2.857,4.033,4.033,0,0,1,1.036-2.873,3.819,3.819,0,0,1,5.372,0A4.03,4.03,0,0,1,66.4,22.156Zm-2.091,0a2.669,2.669,0,0,0-.434-1.59,1.492,1.492,0,0,0-2.4,0,2.669,2.669,0,0,0-.434,1.59,2.635,2.635,0,0,0,.434,1.573,1.492,1.492,0,0,0,2.4,0A2.635,2.635,0,0,0,64.3,22.156Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M72.345,18.314v2.108a2.021,2.021,0,0,0-1.055-.255,1.564,1.564,0,0,0-.935.281,1.3,1.3,0,0,0-.509.79v4.709H67.737v-7.6h2.109V19.1a1.831,1.831,0,0,1,1.7-.918A1.728,1.728,0,0,1,72.345,18.314Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
        <path
          d="M78.142,23.465H80.1a3.031,3.031,0,0,1-1.079,1.947,3.538,3.538,0,0,1-2.288.705,3.472,3.472,0,0,1-2.668-1.1,4.038,4.038,0,0,1-1.02-2.865,4.069,4.069,0,0,1,1.028-2.847,3.4,3.4,0,0,1,2.626-1.13,3.214,3.214,0,0,1,2.542,1.028,3.99,3.99,0,0,1,.909,2.745V22.6H75.082a2.1,2.1,0,0,0,.492,1.334,1.493,1.493,0,0,0,1.156.484A1.278,1.278,0,0,0,78.142,23.465ZM76.714,19.9a1.523,1.523,0,0,0-1.581,1.427h2.94A1.54,1.54,0,0,0,77.7,20.3,1.249,1.249,0,0,0,76.714,19.9Z"
          transform="translate(0.581 -8.872)"
          fill="#262626"
        />
      </g>
      <path
        d="M.16,24.661l83.68-7.322"
        transform="translate(0.581 -8.872)"
        fill="none"
        stroke="#fef900"
        strokeMiterlimit="10"
        strokeWidth="17"
      />
    </g>
  </svg>
);

export default IconLoader;
